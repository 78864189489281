import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import Notifications from 'vue-notification'
import VueMoment from "vue-moment";
import VuetifyConfirm from 'vuetify-confirm'


import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import './registerServiceWorker'


const moment = require('moment')

require('moment/locale/es')

Vue.config.productionTip = false


Vue.use(VuetifyConfirm, { vuetify })
Vue.use(VueMoment, {
  moment
  
})

Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})

Vue.use(Notifications)


axios.defaults.baseURL = 'https://apiferrepos.com';
window.socketAddress = "https://apiferrepos.com";


//axios.defaults.baseURL = 'https://devapi.ferrepos.com';
//window.socketAddress = "https://devapi.ferrepos.com";

// axios.defaults.baseURL = 'http://localhost:3000';
// window.socketAddress = "http://localhost:3000";




const preload = async () => {
  store.dispatch('leerToken');
    new Vue({
      vuetify,
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");

}


preload();